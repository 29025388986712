import React, { useState, useEffect } from "react";
import { useLocation } from "@reach/router";
import queryString from "query-string";
// import groq from "groq";

import client from "../sanity/client";

import Layout from "../components/Layout/Layout";
import Seo from "../components/seo";

import Hero from "../components/Hero/Hero";
import About from "../components/About/About";
import History from "../components/History/History";
import FreshOcean from "../components/FreshOcean/FreshOcean";
import BigPicture from "../components/BigPicture/BigPicture";
import SeeSalmon from "../components/SeeSalmon/SeeSalmon";

const PreviewPage = () => {
  const [page, setData] = useState();
  const location = useLocation();

  useEffect(() => {
    let subscription = undefined;

    if (location && location.search) {
      const { pageId } = queryString.parse(location.search);

      if (pageId) {
        let draftQuery = `*[_id == 'drafts.${pageId}']`;
        let docQuery = `*[_id == '${pageId}']`;

        console.log("query : ", draftQuery);

        client.fetch(draftQuery).then(result => {
          console.log("initial result:", result);
          setData(result[0]);

          if (result.length < 1) {
            client.fetch(docQuery).then(result => {
              console.log("alt result:", result);
              setData(result[0]);
            }) 
          }

          subscription = client.listen(draftQuery).subscribe(update => {
            const data = update.result;
            console.log('subscribed:', data)

            if (data === undefined) {
              client.fetch(docQuery).then(result => {
                console.log("alt result:", result);
                setData(result[0]);
              }) 
            }

            setData(data);
          });
        });
      }
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [location]);

  // useEffect(() => {
  //   if (page) {
  //     console.log("page data:", page);
  //   }
  // }, [page, location]);

  return (
    <Layout>
      <Seo title="Home" />
      {page && page.hero && (
        <Hero text={page.hero.heroText} image={page.hero.heroBackground} />
      )}
      {page && page.om && (
        <About images={page.om.images} text={page.om.content} />
      )}
      {page && page.history && (
        <History
          images={page.history.historyImages}
          text={page.history.historyText}
        />
      )}
      {page && page.fresh && (
        <FreshOcean
          text={page.fresh.content}
          image={page.fresh.image}
          stats={page.fresh.stats}
        />
      )}
      {page && page.bigPicture && <BigPicture image={page.bigPicture} />}
      {page && page.seeSalmon && (
        <SeeSalmon
          image={page.seeSalmon.images}
          text={page.seeSalmon.content}
        />
      )}
    </Layout>
  );
};

export default PreviewPage;
